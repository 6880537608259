<template>
  <section class="main">
    <div class="row statistics-block">
      <div class="col-md-3">
        <div class="statistics-item">
          <div class="icon">
            <i class="fas fa-shopping-cart fa-2x"></i>
          </div>
          <div class="info-text">
            <span class="statistics-text">{{ todayInfo.totalOrderToday }}</span>
            <p class="text-muted mb-1">Заказов сегодня</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics-item">
          <div class="icon">
            <i class="fas fa-truck fa-2x"></i>
          </div>
          <div class="info-text">
            <span class="statistics-text">{{ todayInfo.totalOrderDeliveredToday }}</span>
            <p class="text-muted mb-1">Доставлено</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics-item">
          <div class="icon">
            <i class="fas fa-times fa-2x"></i>
          </div>
          <div class="info-text">
            <span class="statistics-text">{{ todayInfo.totalCanceledOrderToday }}</span>
            <p class="text-muted mb-1">Отмененных</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="statistics-item">
          <div class="icon">
            <i class="fas fa-money-check-alt fa-2x"></i>
          </div>
          <div class="info-text">
            <span class="statistics-text">{{ todayInfo.averageCheckToday }} руб.</span>
            <p class="text-muted mb-1">Средний чек</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            Информация о товарах
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-center">Всего товаров на сайте: {{ productInfo.totalCount }}</li>
          </ul>
          <div class="row town-info">
            <div class="col-md-6">
              <div class="town-item">
                <span class="town">Москва</span>
                <p>Активных: {{ productInfo.total77Count }}</p>
                <p>В наличии: {{ productInfo.total77ActiveCount }}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="town-item">
                <span class="town">Санкт-Петербург</span>
                <p>Активных: {{ productInfo.total78Count }}</p>
                <p>В наличии: {{ productInfo.total78ActiveCount }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4" v-if="aggregatorOrdersInfo.ozon">
        <div class="card">
          <div class="card-header">
            Информация ozon
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-center">Всего заказов: {{ aggregatorOrdersInfo.ozon.totalOrders }}</li>
          </ul>
          <div class="row town-info">
            <div class="col-md-12">
              <p class="text-center">Сумма заказов: {{
                  parseFloat(aggregatorOrdersInfo.ozon.totalAmount.toFixed(2)).toLocaleString('ru-RU').replace(',', '.')
                }} руб.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4" v-if="aggregatorOrdersInfo.yandex">
        <div class="card">
          <div class="card-header">
            Информация yandex
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-center">Всего заказов: {{ aggregatorOrdersInfo.yandex.totalOrders }}</li>
          </ul>
          <div class="row town-info">
            <div class="col-md-12">
              <p class="text-center">Сумма заказов: {{
                  parseFloat(aggregatorOrdersInfo.yandex.totalAmount.toFixed(2)).toLocaleString('ru-RU').replace(',', '.')
                }} руб.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Количество заказов за период
          </div>
          <div class="card-body">
            <date-range-picker
                class="date-range-picker"
                ref="picker"
                v-model="dateRange"
                locale="ru-RU"
                :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
                :format="'YYYY-MM-DD'"
                :showWeekNumbers="false"
                opens="left"
                :timePicker24Hour="true"
                @update="onDateChange"
            ></date-range-picker>
            <line-chart :chart-data="chartData" :height="100"></line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Средний чек за неделю
          </div>
          <div class="card-body">
            <bar-chart :chart-data="avgCheckData" :height="200"></bar-chart>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Выручка за неделю
          </div>
          <div class="card-body">
            <bar-chart :chart-data="amountWeekData" :height="200"></bar-chart>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import LineChart from './charts/line-chart'
import BarChart from './charts/bar-chart'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'index',
  components: { LineChart, BarChart, DateRangePicker },
  data: function () {
    return {
      chartData: [],
      avgCheckData: [],
      amountWeekData: [],
      dateRange: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(6, 'days').format('YYYY-MM-DD')
      },
    }
  },
  computed: {
    ...mapGetters('index', ['todayInfo', 'productInfo', 'ordersChart', 'weekChart', 'aggregatorOrdersInfo']),
  },
  async mounted () {
    await this.fetchTodayInfo()
    await this.fetchProductInfo()
    await this.fetchOrdersChart()
    await this.fetchWeekChart()
    await this.fetchAggregatorOrdersInfo()
  },
  methods: {
    fetchTodayInfo () {
      this.$store.dispatch('index/getTodayInfo')
    },
    fetchProductInfo () {
      this.$store.dispatch('index/getProductInfo')
    },
    fetchAggregatorOrdersInfo () {
      this.$store.dispatch('index/getAggregatorOrders')
    },
    fetchOrdersChart () {
      this.$store.dispatch('index/getOrdersChart', this.dateRange).then(() => {
        this.initChartOrders()
      })
    },
    fetchWeekChart () {
      this.$store.dispatch('index/getWeekChart').then(() => {
        this.initChartAvgCheckWeek()
        this.initChartAmountWeek()
      })
    },
    initChartOrders: function () {
      this.chartData = {
        labels: this.ordersChart.map(c => c.date),
        datasets: [
          {
            label: 'Общее',
            data: this.ordersChart.map(c => c.count),
            backgroundColor: [
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)'
            ],
            borderColor: [
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)'
            ],
            borderWidth: 1
          },
          {
            label: 'Отмены',
            data: this.ordersChart.map(c => c.canceled),
            backgroundColor: [
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
              'rgba(255,0,0, 0.2)',
            ],
            borderColor: [
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
              'rgba(255,0,0, 1)',
            ],
            borderWidth: 1
          },
        ],
      }
    },
    initChartAvgCheckWeek: function () {
      this.avgCheckData = {
        labels: this.weekChart.map(c => c.date),
        datasets: [
          {
            label: "Средний чек",
            data: this.weekChart.map(c => parseFloat(c.amount / c.count).toFixed(2)),
            backgroundColor: [
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)'
            ],
            borderColor: [
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)'
            ],
            borderWidth: 1
          },
        ],
      }
    },
    initChartAmountWeek: function () {
      this.amountWeekData = {
        labels: this.weekChart.map(c => c.date),
        datasets: [
          {
            label: "Выручка",
            data: this.weekChart.map(c => parseFloat(c.amount).toFixed(2)),
            backgroundColor: [
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)',
              'rgba(0,124,186, 0.2)'
            ],
            borderColor: [
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)',
              'rgba(0,124,186, 1)'
            ],
            borderWidth: 1
          },
        ],
      }
    },
    onDateChange (value) {
      this.dateRange.startDate = moment(value.startDate).format('YYYY-MM-DD')
      this.dateRange.endDate = moment(value.endDate).format('YYYY-MM-DD')
      this.fetchOrdersChart()
    },
  },
}
</script>

<style lang="scss" scoped>
.vue-daterange-picker {
  width: 300px;
}

.statistics-block {
  .statistics-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #e7eaed;
    box-shadow: 0 2px 10px 0 rgba(182, 182, 182, 0.25);

    .icon {
      padding: 20px;

      i {
        color: #363740;
      }
    }

    .info-text {
      padding: 10px;

      .statistics-text {
        text-align: right;
        display: block;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

.town-info {
  padding: .5rem 1rem;

  .town-item {
    text-align: center;
  }

  .col-md-6:first-child {
    border-right: 1px solid #000000;
  }
}
</style>